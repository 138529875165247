'use client';

import { createContext, ReactNode, useContext } from 'react';
import { ConfigData } from './config-context-data';

const ConfigContextState = createContext<ConfigData>({
  wsBaseUrl: '',
});

export const useConfig = () => {
  const config = useContext(ConfigContextState);

  return config;
};

export const ConfigContextClient = ({
  children,
  data,
}: {
  children: ReactNode;
  data: ConfigData;
}) => {
  return (
    <ConfigContextState.Provider value={data}>
      {children}
    </ConfigContextState.Provider>
  );
};
